<template>
  <div
    :aria-hidden="!opened"
    :class="{opened,hiding,hidden}"
    class="__collapsible"
    :style="`--items-height:${height}px`"
    @animationend="animationEnd"
  >
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    opened: {
      default: false
    }
  },
  data () {
    return {
      hiding: false,
      hidden: true,
      height: undefined
    }
  },
  watch: {
    opened (v) {
      this.requestedFrame = requestAnimationFrame(x => {
        this.height = this.$el.scrollHeight
      })
      if (v) {
        this.hidden = false
        this.hiding = false
      } else {
        this.hiding = true
      }
    }
  },
  created () {
    if (this.opened) {
      this.hidden = false
    }
  },
  methods: {
    animationEnd (e) {
      if (!this.opened) {
        this.hidden = true
      }
      this.hiding = false
    }
  }
}
</script>

<style lang="scss" scoped>
.__collapsible {
  padding: 0 !important; //запрет переопределять (потому что зараза не посчитаешь потом высоту блин)
  overflow: hidden;
  animation: prolapsing 0.35s ease 0s 1 normal forwards;

  &.hidden {
    display: none;
  }

  &.hiding {
    animation-name: collapsing;
  }
}

@keyframes prolapsing {
  0% {
    margin-top: 0;
    height: 0;
    max-height: 0;
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    margin-top: var(--items-margin-top, 0px);
    height: var(--items-height, 0px);
    max-height: var(--items-height, 0px);
    opacity: 1;
  }
}

@keyframes collapsing {
  0% {
    margin-top: var(--items-margin-top, 0px);
    height: var(--items-height, 0px);
    max-height: var(--items-height, 0px);
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    margin-top: 0;
    height: 0;
    max-height: 0;
    opacity: 0;
  }
}
</style>
